$(document).ready(function () {
    $("#openSilderBar").click(function () {
        $("#mySidenav").css("width", "300px");
        $("#wrapSidenav").css("width", "100%");
    });

    $("#bg-black").click(function () {
        $("#mySidenav").css("width", "0");
        $("#wrapSidenav").css("width", "0");
    });
});
